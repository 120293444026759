<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Сурталчилгааны цаг тохируулах
      </h3>
      <div class="action-section">
        <el-button type="primary" @click="addNew">Үүсгэх</el-button>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-select
              v-model="dayOfName"
              placeholder="Гариг"
              @change="searchTimeLimit"
              filterable
              allow-create
              size="mini"
              clearable
            >
              <el-option value="Mon" key="0" label="Даваа"></el-option>
              <el-option value="Tue" key="1" label="Мягмар"></el-option>
              <el-option value="Wed" key="2" label="Лхагва"></el-option>
              <el-option value="Thu" key="3" label="Пүрэв"></el-option>
              <el-option value="Fri" key="4" label="Баасан"></el-option>
              <el-option value="Sat" key="5" label="Бямба"></el-option>
              <el-option value="Sun" key="6" label="Ням"></el-option>
            </el-select>
            <el-select
              placeholder="Төлөв"
              size="mini"
              v-model="is_show"
              @change="searchTimeLimit"
              clearable
            >
              <el-option value="1" key="1" label="Идэвхтэй"></el-option>
              <el-option value="0" key="0" label="Идэвхгүй"></el-option>
            </el-select>
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              @input="searchTimeLimit"
              size="mini"
              placeholder="Хайх"
              clearable
            />
          </div>

          <el-table
            :data="timeLimits"
            size="mini"
            style="width: 95%,margin: 100vh;"
          >
            <el-table-column
              prop="name_eng"
              label="Англи нэр"
            ></el-table-column>
            <el-table-column
              prop="name_mon"
              label="Монгол нэр"
            ></el-table-column>
            <el-table-column prop="dayOfName" label="Гариг">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-for="(day, dayIndex) in scope.row.dayOfName"
                  :key="dayIndex"
                >
                  {{ day }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="start_date"
              label="Эхлэх өдөр"
            ></el-table-column>
            <el-table-column
              prop="end_date"
              label="Дуусах өдөр"
            ></el-table-column>
            <el-table-column
              prop="start_time"
              label="Эхлэх цаг"
            ></el-table-column>
            <el-table-column
              prop="end_time"
              label="Дуусах цаг"
            ></el-table-column>
            <el-table-column prop="is_show" label="Төлөв">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-if="scope.row.is_show == 1"
                  type="success"
                  effect="dark"
                >
                  Идэвхтэй
                </el-tag>
                <el-tag
                  size="mini"
                  v-else-if="scope.row.is_show == 0"
                  type="info"
                  effect="dark"
                  >Идэвхгүй</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="Засах">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-edit"
                  style="margin-top: 10px;"
                  @click="edit(scope.row)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="Устгах">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-edit"
                  @click="deleteItem(scope.row.id, scope.row.name_eng)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageCount"
            @current-change="setPage"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="selTimeLimit"
      :title="selTimeLimit.id > 0 ? 'Засах' : 'Үүсгэх'"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="ruleForm"
        :model="selTimeLimit"
        :rules="rules"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-form-item label="Монгол нэр:" prop="name_mon">
          <el-input
            v-model="selTimeLimit.name_mon"
            placeholder="Монгол нэрээ оруулна  уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Англи нэр:" prop="name_eng">
          <el-input
            v-model="selTimeLimit.name_eng"
            placeholder="Англи нэрээ оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Гариг:" prop="dayOfName">
          <el-select
            v-model="selTimeLimit.dayOfName"
            @change="$forceUpdate()"
            multiple
            placeholder="Англи нэрээ оруулна уу?"
          >
            <el-option label="Даваа" value="Mon"> </el-option>
            <el-option label="Мягмар" value="Tue"> </el-option>
            <el-option label="Лхагва" value="Wed"> </el-option>
            <el-option label="Пүрэв" value="Thu"> </el-option>
            <el-option label="Баасан" value="Fri"> </el-option>
            <el-option label="Бямба" value="Sat"> </el-option>
            <el-option label="Ням" value="Sun"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Эхлэх өдөр:" prop="start_date">
          <el-date-picker
            v-model="selTimeLimit.start_date"
            type="date"
            @blur="$forceUpdate()"
            placeholder="Эхлэх өдрөө сонгоно уу?"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Дуусах өдөр:" prop="end_date">
          <el-date-picker
            v-model="selTimeLimit.end_date"
            type="date"
            @blur="$forceUpdate()"
            placeholder="Дуусах өдрөө сонгоно уу?"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Эхлэх цаг:" prop="start_time">
          <el-time-select
            v-model="selTimeLimit.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Эхлэх цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="Дуусах цаг:" prop="end_time">
          <el-time-select
            v-model="selTimeLimit.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Дуусах цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="Төлөв:" prop="is_show">
          <el-switch
            v-model="selTimeLimit.is_show"
            active-text="Идэвхитэй"
            @change="$forceUpdate()"
            inactive-text="Нуусан"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="success" @click="createOrUpdate">{{
          selTimeLimit.id > 0 ? "Засах" : "Үүсгэх"
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import service from "@/helpers/services.js";
export default {
  name: "TimeLimitBanner",
  created() {
    this.getTimeLimitBanner();
  },
  data() {
    return {
      selTimeLimit: {},
      dialogVisible: false,
      rules: {
        name_mon: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        dayOfName: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        start_date: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        end_date: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        start_time: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        end_time: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        is_show: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "change"
          }
        ]
      },
      timeLimits: null,
      pageCount: 0,
      offset: 1,
      limit: 10,
      search: "",
      page: 1,
      dayOfName: "",
      is_show: null
    };
  },
  methods: {
    deleteItem(id, name_eng) {
      const payload = {
        id: id,
        name_eng: name_eng,
        type: "kitchenBanner"
      };
      this.$confirm("Устгах гэж байна шүү?", "Анхааруулга", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteAppBannerTimeLimit(payload).then(res => {
            if (res.status === "success") {
              this.dialogVisible = false;
              this.$notify({
                title: "Амжилттай",
                message: res.message,
                type: "success"
              });
              this.offset = 1;
              this.getTimeLimitBanner();
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                dangerouslyUseHTMLString: true,
                message: res.message + "<br/>" + "<li>" + res.product + "</li>",
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    edit(item) {
      this.selTimeLimit = Object.assign({}, item);
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate();
      this.dialogVisible = true;
    },
    createOrUpdate() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.selTimeLimit.start_date.length !== 10) {
            const date = new Date(this.selTimeLimit.start_date);
            this.selTimeLimit.start_date = new Date(
              date.toString().split("GMT")[0] + " UTC"
            )
              .toISOString()
              .slice(0, 10)
              .replace("T", " ");
          }
          if (this.selTimeLimit.end_date.length !== 10) {
            const date = new Date(this.selTimeLimit.end_date);
            this.selTimeLimit.end_date = new Date(
              date.toString().split("GMT")[0] + " UTC"
            )
              .toISOString()
              .slice(0, 10)
              .replace("T", " ");
          }
          this.selTimeLimit.type = "kitchenBanner";
          this.selTimeLimit.end_date.length;
          if (this.selTimeLimit.id > 0) {
            service.updateAppBannerTimeLimit(this.selTimeLimit).then(res => {
              if (res.status === "success") {
                this.dialogVisible = false;
                this.$notify({
                  title: "Амжилттай",
                  message: res.message,
                  type: "success"
                });
                this.getTimeLimitBanner();
              } else {
                this.$notify({
                  title: "Алдаа гарлаа",
                  message: res.message,
                  type: "warning"
                });
              }
            });
          } else {
            service.createAppBannerTimeLimit(this.selTimeLimit).then(res => {
              if (res.status === "success") {
                this.dialogVisible = false;
                this.$notify({
                  title: "Амжилттай",
                  message: res.message,
                  type: "success"
                });
                this.getTimeLimitBanner();
              } else {
                this.$notify({
                  title: "Алдаа гарлаа",
                  message: res.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Алдаа",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    addNew() {
      const date = new Date();
      const formattedDate = new Date(date.toString().split("GMT")[0] + " UTC")
        .toISOString()
        .slice(0, 10)
        .replace("T", " ");
      date.setMonth(date.getMonth() + 6);
      const formattedDate1 = new Date(date.toString().split("GMT")[0] + " UTC")
        .toISOString()
        .slice(0, 10)
        .replace("T", " ");
      this.selTimeLimit = {};
      this.selTimeLimit.start_date = formattedDate;
      this.selTimeLimit.end_date = formattedDate1;
      this.selTimeLimit.start_time = "10:00:00";
      this.selTimeLimit.end_time = "22:00:00";
      this.selTimeLimit.is_show = true;
      this.selTimeLimit.dayOfName = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
      ];
      this.dialogVisible = true;
    },
    getTimeLimitBanner() {
      this.openFullScreenLoader(true);
      let payload = {};
      payload.offset = this.offset;
      payload.limit = this.limit;
      payload.search = this.search;
      payload.dayOfName = this.dayOfName;
      payload.is_show = this.is_show;
      payload.type = "kitchenBanner";
      service.getTimeLimitConfigsforBanner(payload).then(res => {
        if (res.status === "success") {
          this.timeLimits = res.data;
          this.pageCount = res.total;
        } else {
          this.$notify({
            title: "Алдаа гарлаа",
            message: res.message,
            type: "warning"
          });
        }
      });
      this.openFullScreenLoader(false);
    },
    setPage(val) {
      this.offset = val;
      this.getTimeLimitBanner();
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "тохиргоо мэдээлэл татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    searchTimeLimit() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.page = 1;
        this.getTimeLimitBanner();
      }, 1000);
    }
  }
};
</script>
<style>
.day {
  font-size: 12px;
  color: #777;
  margin-bottom: 15px;
}
.time {
  font-size: 11px;
  color: #999;
  margin-bottom: 15px;
}
.tag {
  float: left;
  margin-top: 5px;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
